.form-control {
    padding-bottom: 10px!important;
    background-color: transparent;
    border: 1px solid white;
    color: var(--WhiteColor);
}
.form-control:focus {
    background: transparent;
    color: var(--WhiteColor);
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}

#contactForm {
    color: #ff4c4c !important;
}

#submitButton {
    width: 100%;
    font-weight: bold;
    border: none;
    background: none;
    outline: none;
    color: black;
    mix-blend-mode: screen;
}

.btn-primary:hover {
    opacity: 90%;
    transition:220ms ease-in;
}

.redes-contact {
    width: 200px;
    margin-bottom: 50px;
}
label {  
    color: var(--WhiteColor);
}
#contact-button:active{
    background-color: transparent !important;
}

.form-floating > .form-control, .form-floating > .form-select {
    height: calc(2.5rem + 2px) !important;
} 
.css-1dimb5e-singleValue, .css-1dimb5e-singleValue{
    -webkit-text-fill-color: var(--WhiteColor);
}