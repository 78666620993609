.title-fecha {
    font-family: var(--Principal-fuente) !important;
    text-transform: uppercase;
    color: var(--WhiteColor);
    display: flex;
    font-weight: 200;

}
.date-fecha {
    font-family: var(--Principal-fuente) !important;
    text-transform: uppercase;
    color: var(--WhiteColor);
    font-size: 12px;
    min-height: 20px;
    display: flex;
    font-weight: 200;
}

#icon:hover{
    color: var(--WhiteColor);
    transition:120ms ease-in;
}
.redes-sociales {
    width: 65%;
    margin-bottom: 20px;
}
.linea-flecha {
    border-bottom: 1px solid var(--BlackColor);; 
}

#div-fechas{
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 40px;
}  

@media only screen and (max-width:992px){
    .redes-sociales {
        width: 50%;
    }
    .img-fluid {
        max-width: 50%;
    }
    #div-fechas{
        padding-top: 15px;
        padding-left: 15px;
        padding-bottom: 15px;
    }  
}
@media screen and (max-width: 1400px){
    .title-fecha {
        font-size: 20px;
    }
    .date-fecha  {
        font-size: 15px;
    }
    .prueba {
        width:60px
    }
}

a:hover {
    opacity: 70%;
}

#blanco {
    background-color: var(--WhiteColor);
    h1,h4,p{color: var(--BlackColor)}
}
.row-fechas {
    width: 50%;
}

.section-fechas {
    display: ruby;
}
@media (max-width: 992px) {
    .title-fecha .col-sm {
        width: 50%; 
    }
    .title-fecha  {
        font-size: 14px;
    }
    .date-fecha  {
        font-size: 10px;
        margin-bottom: 0px;
    }
    .row-fechas {
        width: 50%;
        display: flex;
        flex-wrap: nowrap;
    }
    .section-fechas {
        display: block;
    }
    .prueba {
        width:50px
    }
}