    .slick-slide img {
        margin: auto;
        padding: 0px;
        width: 200px;

    }
    
    .brands {
        padding: 40px;
        
    }