.row-nosotros {
  justify-content: center;
}
.legales {
  width: 100%;
  text-align: left;
}
.col-lg-3 > img {
  margin-bottom: 20px;
}
.list {
  font-size: 0.75em;
}