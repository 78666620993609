@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

@import '../components/Fechas/styles.module.scss';
@import '../components/Contacto/styles.module.scss';
@import '../components/Festival/styles.module.scss';
@import '../components/Eventos/styles.module.scss';
@import '../components/Footer/styles.module.scss';
@import '../components/Nav/styles.module.scss';
@import '../components/Nosotros/styles.module.scss';
@import '../components/Trabajos/styles.module.scss';
@import '../components/Inicio/styles.module.scss';
@import '../components//AvisoLegal/styles.module.scss';
@import '../components/Acreditacion/styles.module.scss';

@font-face {
    font-family: "Blatant";
    src: url(../../src/assets/font/Blatant.otf);
}
@font-face {
    font-family: "BlatantBold";
    src: url(../../src/assets/font/Blatant-Bold.otf);
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

:root {
    --FirstColor: #fbfbfb;
    --BlackColor: #111111;
    --WhiteColor: #fffffff3;
    --Principal-fuente: 'Poppins', sans-serif;
    --Secundaria-fuente: "Blatant";  
    --Blatant-Bold-fuente: "BlatantBold";  
}

html,
body {
    font-family: var(--Principal-fuente);
    box-sizing: border-box;
    background-color: var(--BlackColor);
    color: var(--BlackColor);
}

.h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    font-family: var(--Secundaria-fuente);
    color: var(--FirstColor);
}

.page-section {
    min-height: 100vh;
    padding-top: 50px;
}

.page-sectionWhite {
    min-height: 100vh;
    padding-top: 50px;
    background-color: var(--WhiteColor);
}

p {
    font-size: 12px;
}
.container-body {
    padding-right: var(--bs-gutter-x, 7.75rem);
    padding-left: var(--bs-gutter-x, 7.75rem);
}

@media only screen and (max-width:1224px) {
        .container-body {
            padding-right: 2rem;
            padding-left: 2rem;
        }
    }

// Definir estilos de botónes 

.btn-primary {
    color: #ff254d!important;
    background-color: var(--WhiteColor)!important;
}

// FORM INPUT'S STYLES //

.form-control {
    padding-bottom: 10px!important;
    background-color: transparent;
    border: 1px solid var(--WhiteColor);
    font-size: 0.85rem;
}
.form-control:focus {
    background: transparent;
}

// SOCIAL MEDIA ICONS //

.btn-social {
    color: var(--WhiteColor) !important;
}

.btn-social:hover {
    opacity: 1% !important;
}