.tickets-festival{
    background-color: rgb(28, 75, 205);
    height: 61px;
}

#festival {
    background-image: url(../../assets/images/DaleFondoOk2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

#boton-festival {
    color: white !important;
    background: #64a9d6 !important;
    border-color: white;
    width: 176px;
    height: 44px;
}