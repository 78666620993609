.footer {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: var(--BlackColor);
  color: var(--WhiteColor);
}
.lead {
    font-size: 0.45rem;
}
.footer-description {
  height: 40px;
}

#copy {
  font-size: 10px;
}

#avisosLegales {
    font-size: 0.75em;
    cursor: pointer;
    font-weight: 200;
}

@media only screen and (max-width: 992px) {
    #avisosLegales {
        font-size: 0.6em;
    }
}

#pampa {
    font-size: 0.6em;
    > a { color: rgb(159, 159, 159); }
}

#pampaLogo {
    margin-left: 4px;
}

#trtLogo {
    display: flex;
}