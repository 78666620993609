#inicio {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

#title {
    text-shadow: 2px 2px 0px rgba(0,0,0,0.52);
}

#boton-inicio {
    color: black;
    background-color: white;
    border-color: black;
    padding: 8px 18px;
    margin-bottom: 5px;
    margin-top: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    font-size: 14px;

    span {
        font-family: 'Roboto', sans-serif;
        position: relative;
        z-index: 100;
    }

    &:hover {
        color: white;
        background-color: transparent;
        border: 1px solid rgb(255, 255, 255);

        transition:all .4s ease-in-out;
    }
}

@media (max-width:800px){
    .page-section {
        min-height: 70vh !important;
    }
}
#textInfo {
    color: white !important;
}

#tickets-inicio{
    background-color: var(--BlackColor) !important;
}

  @media screen and (max-width: 767px){
    .bXvhnh {
        grid-template-columns: repeat(5,82%) !important;
        grid-template-rows: 1fr;
    }}

    #flyer {
        width: auto;
    }

    #modal-button {
        color: white !important;
        background-color: black !important;
        border-color: white;
        padding: 8px 18px;
        margin-bottom: 30px;
        margin-top: 15px;
        text-transform: uppercase;
        letter-spacing: 2px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        font-size: 14px;
    
        span {
            font-family: 'Roboto', sans-serif;
            position: relative;
            z-index: 100;
        }
    
        &:hover {
            color: black !important;
            background-color: white !important;
            border: 1px solid black;
    
            transition:all .4s ease-in-out;
        }
    }

    #flyer img {
        border-radius: 2px;
    }

hr {
    color: white;
    width: 100%;
}