#submitButton {
    width: 100%;
    font-weight: bold;
    border: none;
    background: none;
    outline: none;
    color: black;
    mix-blend-mode: screen;
}

.btn-primary:hover {
    opacity: 90%;
    transition:220ms ease-in;
}

.redes-contact {
    width: 200px;
    margin-bottom: 50px;
}
label {  
    color: var(--WhiteColor);
}

#react-select{  
    color: var(--WhiteColor);
}

.form-control  {
    padding-top: 8px !important; 
    padding-bottom: 8px !important;
    height: 42px !important;
}

.css-1xfyymd-control {
    background-color: transparent;
}

#texto-info {
    font-size: 1em !important;
    font-weight: 200 !important;
}

#acreditacionesForm {
    color: #ff4c4c !important;
}