.navbar {
    position: relative;
}

.navbar-nav {
  gap: 64px;
}

@media only screen and (max-width: 1224px) {
  .navbar-nav {
    gap: 0px;
  }
  #logo {
    display: none;
  }
  #logoInstagram {
    display: none;
  }
}

@media only screen and (min-width:1224px) {
  #logo {
    display:block;
  }
}

.drop {
  display: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0rem;
}

.navbarcustom {
  background-color: (var(--BlackColor));
}

.navbar-expand-lg .navbar-collapse {
    justify-content: center;
}

.dropdown {
  color: var(--WhiteColor);
  font-weight: 200;
  font-size: .85em;
  text-transform: uppercase;
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}
.dropdown:active {
  color: #bbbbbb !important;
}
.dropdown:hover {
color: (#a4a4a4);
}
.dropdown:focus {
  color: var(--WhiteColor);
  border-bottom: 1px solid white;
  padding-bottom: 2px;
}

.nav-link {
  color: var(--WhiteColor);
  font-weight: 300;
  font-size: .85em;
  text-transform: uppercase;
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}
.nav-link:active {
    color: #bbbbbb !important;
}
.nav-link:hover {
  color: (#a4a4a4);
}
.nav-link:focus {
    color: var(--WhiteColor);
    border-bottom: 1px solid white;
}

#contact-button:focus { 
  color: var(--WhiteColor);
  border-bottom: 0px solid white;
}

button {
  background-color: (var(--BlackColor));
  border: none;
  padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
}

.dropdown-item {
  color: var(--WhiteColor);
  font-weight: 200;
  font-size: .75em;
  text-transform: uppercase;
  padding-right: 0rem !important;
  padding-left: 0.3rem !important;
}
.dropdown-item:active {
  color: #bbbbbb !important;
}
.dropdown-item:hover {
color: (#a4a4a4);
}
.dropdown-item:focus {
  color: var(--WhiteColor);
  border-bottom: 1px solid white;
  padding-bottom: 2px;
}

.dropdown-menu {
  background: var(--BlackColor) !important;
}

.logo-container {
  display: flex;
  align-items: center;
}

#logo {
  cursor: pointer;
}

@media only screen and (min-width:992px){
    #logo-responsive{
        display:none;
    }
}
@media only screen and (max-width:991px){
    .nav-link {
        align-self: center;
    }
}

#logo-responsive{
    margin-right: 30px;
}
#logo{
    margin-left: 30px;
}

#logoInstagram {
  margin-right: 30px;
  cursor: pointer;
}

@media only screen and (max-width:992px){
  #dropdown-navbar{
      display:none;
  }
}

@media only screen and (min-width:992px){
  .items-contacto{
      display:none;
  }
}

.navbar-nav {
  align-items: center;
}

.dropdown-item:hover {
  color: var(--WhiteColor);
  background-color: rgb(44, 44, 44);
}