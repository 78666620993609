.row-nosotros {
  justify-content: center;
}
.nosotros {
  width: 55%;
  text-align: left;
}
.col-lg-3 > img {
  margin-bottom: 20px;
}
.nosotrosTexto {
  text-transform: uppercase;
  font-size: 20px;
  font-family: var(--Secundaria-fuente);
  line-height: 20px;
  letter-spacing: 2px;
}

@media only screen and (max-width: 992px) {
  .nosotros {
    width: 100%;
  }
  .container {
    padding-left: 10%;
    padding-right: 10%;
  }
    .nosotrosTexto {
        font-size: 12px;
    }
}
#nosotrosParrafo {
    font-family: var(--Principal-fuente) !important;
    display: flex;
    font-weight: 200;
    line-height: 25px;
}
